<template>
    <ul class="documents">
        <li
            v-for="(document, index) in items"
            :key="index"
            class="documents__item"
        >
            <a :href="document.link" target="_blank" download>
                {{ document.title }}
            </a>
        </li>
    </ul>
</template>

<script>
import './documents.scss'

export default {
    name: 'Documents',
    props: {
        items: {
            type: Array,
            required: true
        }
    },
}
</script>